<template>
  <input
    :id="value.id"
    :checked="isMetadataValueChecked(currentInstance, value.id)"
    :name="currentInstance.metadata_definition.name"
    :value="value.display_name"
    type="radio"
    @change="toggleMetadataAllocatedValue"
  />
</template>

<script lang="ts" setup>
import { PropType } from "vue";
import { MetadataAllowedValues } from "@/models/content-templates.model";
import { isMetadataValueChecked } from "@/utils/content-metadata-helpers";
import { MetadataInstance } from "@/models/product-section.model";
import { useMetadataStore } from "@/stores/metadata.store";

const metadataStore = useMetadataStore();

const props = defineProps({
  instances: {
    required: true,
    type: Array as PropType<MetadataInstance[]>
  },
  value: {
    required: true,
    type: Object as PropType<MetadataAllowedValues>
  },
  currentInstance: {
    required: true,
    type: Object as PropType<MetadataInstance>
  },
  productId: {
    required: true,
    type: Number
  }
});

const getMetadataSingleAllocatedValueId = (): number | undefined => {
  if (
    props.currentInstance.metadata_definition.metadata_allocated_values.length >
    0
  ) {
    return props.currentInstance.metadata_definition
      .metadata_allocated_values[0].id;
  }
  return undefined;
};
const toggleMetadataAllocatedValue = async () => {
  const id = getMetadataSingleAllocatedValueId();
  if (id) {
    // delete old value
    const payloadToRemove = {
      id,
      productId: props.productId
    };
    await metadataStore.deleteMetadataAllocatedValues(payloadToRemove, false);
    // set new value
    const payloadToAdd = {
      value: {
        metadata_allowed_value_id: Number(props.value.id),
        metadata_instance_id: props.currentInstance.id
      },
      productId: props.productId
    };
    await metadataStore.createMetadataAllocatedValues(payloadToAdd);
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <div
    :class="[
      'main-layout',
      { 'right-sidebar-padding': uiGeneralStore.isRightSidebarOpen }
    ]"
    data-testing="main-container"
  >
    <teleport to="body">
      <b-modal
        :model-value="uiFilesStore.isAttachFileModalOpen"
        :has-modal-card="true"
        :trap-focus="true"
        aria-role="dialog"
        :aria-modal="true"
        @cancel="uiFilesStore.closeAttachFileModal"
        class="full-screen-modal"
      >
        <AttachFile />
      </b-modal>

      <b-modal
        :model-value="uiContentTemplatesStore.isAddMetadataModalOpen"
        :has-modal-card="true"
        :trap-focus="true"
        aria-role="dialog"
        :aria-modal="true"
        @cancel="uiContentTemplatesStore.closeAddMetadataModal"
        class="full-screen-modal"
      >
        <AddMetadata />
      </b-modal>

      <b-modal
        :model-value="uiProductsStore.isProductImageModalVisible"
        :has-modal-card="true"
        :trap-focus="true"
        aria-role="dialog"
        :aria-modal="true"
        @cancel="uiProductsStore.closeProductImageModal"
        class="full-screen-modal"
      >
        <AddProductImage />
      </b-modal>

      <ContentSidebar />
      <FileSidebar />
      <CreateSection />

      <ToggleEditMode />
    </teleport>

    <Sidebar />
    <div>
      <Header />
      <div class="main-view">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import Sidebar from "@/components/sidebar/Sidebar.vue";
import Header from "@/components/header/Header.vue";
import ContentSidebar from "@/components/right-sidebars/content-sidebar/ContentSidebar.vue";
import CreateSection from "@/components/right-sidebars/CreateSection.vue";
import FileSidebar from "@/components/right-sidebars/FileSB.vue";
import AttachFile from "@/components/popups/attachments/attach-file/AttachFile.vue";
import AddMetadata from "@/components/popups/AddMetadata.vue";
import AddProductImage from "@/components/popups/AddProductImage.vue";
import ToggleEditMode from "@/components/header/ToggleEditMode.vue";
import { useUIProductsStore } from "@/stores/ui-products.store";
import { useUIFilesStore } from "@/stores/ui-files.store";
import { useUIContentTemplatesStore } from "@/stores/ui-content-template.store";
import { useUIGeneralStore } from "@/stores/ui-general.store";

const uiProductsStore = useUIProductsStore();
const uiFilesStore = useUIFilesStore();
const uiContentTemplatesStore = useUIContentTemplatesStore();
const uiGeneralStore = useUIGeneralStore();
</script>
<style lang="scss" scoped>
.main-layout {
  transition: padding-right 0.3s ease-out;
}
#app .full-screen-modal,
.dialog.modal.is-active {
  z-index: 902; // Must be greater than the z-index of the sidebar
}
</style>

<template>
  <header
    id="main-header"
    :class="{ 'is-sidebar-open': uiGeneralStore.isSidebarOpen }"
  >
    <UpperHeader />
    <LowerHeader v-if="productLinks.length" :links="productLinks" />
    <LowerHeader
      v-if="isContentPage && contentLinks.length"
      :links="contentLinks"
    />
    <LowerHeader
      v-if="isProductPermissionsPage && productPermissionsLinks.length"
      :links="productPermissionsLinks"
    />
  </header>
</template>

<script lang="ts" setup>
import UpperHeader from "@/components/header/UpperHeader.vue";
import LowerHeader from "@/components/header/LowerHeader.vue";
import { HeaderNavigationLink } from "@/models/header.model";
import { computed } from "vue";
import { useUIGeneralStore } from "@/stores/ui-general.store";
import { useProductsManager } from "@/composables/products-manager";
import { useRoute } from "vue-router";
import { useProductFeaturesManager } from "@/composables/product-features-manager";
import { useTasksQueryManager } from "@/composables/tasks-query-manager";

const uiGeneralStore = useUIGeneralStore();

const route = useRoute();
const currentRoute = computed<string | symbol | undefined>(() => {
  return route.name;
});
const isContentPage = computed<boolean>(() => {
  return (
    currentRoute.value === "content" ||
    currentRoute.value === "content-templates" ||
    currentRoute.value === "assets"
  );
});
const isProductPermissionsPage = computed<boolean>(() => {
  return (
    currentRoute.value === "product-permissions" ||
    currentRoute.value === "feature-management"
  );
});

const { product, isProductArchived } = useProductsManager();

const {
  isAssetManagementFeatureEnabled,
  isWorkflowManagementFeatureEnabled,
  isContentAuthoringTier1FeatureEnabled
} = useProductFeaturesManager();

const { getTasksQuery } = useTasksQueryManager();

const productLinks = computed<HeaderNavigationLink[]>(() => {
  let defaultLinks: HeaderNavigationLink[] = [];
  if (product.value) {
    defaultLinks = [
      {
        title: "Product",
        isHighlighted: currentRoute.value === "product",
        to: {
          name: "product",
          params: { productId: product.value.id }
        },
        isEnabled: true
      },
      {
        title: "Content",
        dataTesting: "navigate-to-content",
        isHighlighted: isContentPage.value,
        to: {
          name: isContentAuthoringTier1FeatureEnabled.value
            ? "content"
            : "content-templates",
          params: { productId: product.value.id }
        },
        isEnabled:
          isContentAuthoringTier1FeatureEnabled.value &&
          isAssetManagementFeatureEnabled.value &&
          !isProductArchived.value
      },
      {
        title: "Tasks",
        dataTesting: "navigate-to-tasks",
        isHighlighted: currentRoute.value === "productTasks",
        to: {
          name: "productTasks",
          params: { productId: product.value.id },
          query: getTasksQuery() as unknown as Record<string, string>
        },
        isEnabled:
          isWorkflowManagementFeatureEnabled.value && !isProductArchived.value
      },
      {
        title: "Progress",
        dataTesting: "navigate-to-progress",
        isHighlighted: currentRoute.value === "progress",
        to: {
          name: "progress",
          params: { productId: product.value.id }
        },
        isEnabled:
          isWorkflowManagementFeatureEnabled.value && !isProductArchived.value
      },
      {
        title: "Planning",
        dataTesting: "navigate-to-planning",
        isHighlighted: currentRoute.value === "planning",
        to: {
          name: "planning",
          params: { productId: product.value.id }
        },
        isEnabled:
          isWorkflowManagementFeatureEnabled.value && !isProductArchived.value
      },
      {
        title: "Product Permissions",
        isHighlighted: isProductPermissionsPage.value,
        to: {
          name: "product-permissions",
          params: { productId: product.value.id }
        },
        isEnabled: !isProductArchived.value
      }
    ];
    if (product.value.adaptive_learning_systems.length > 0) {
      return [
        ...defaultLinks,
        {
          title: "Adaptive Learning Systems",
          isHighlighted:
            currentRoute.value === "adaptive-learning-systems-list" ||
            currentRoute.value === "adaptive-learning-system",
          to: {
            name: "adaptive-learning-systems-list",
            params: { productId: product.value.id }
          },
          isEnabled:
            !!product.value &&
            product.value.adaptive_learning_systems.length > 0
        }
      ];
    }
  }
  return defaultLinks;
});
const contentLinks = computed<HeaderNavigationLink[]>(() => {
  if (product.value) {
    return [
      {
        title: "Navigation",
        isHighlighted: currentRoute.value === "content",
        to: {
          name: "content",
          params: { productId: product.value.id }
        },
        isEnabled: isContentAuthoringTier1FeatureEnabled.value
      },
      {
        title: "Assets",
        dataTesting: "navigate-to-workflows",
        isHighlighted:
          currentRoute.value === "content-templates" ||
          currentRoute.value === "assets",
        to: {
          name: "content-templates",
          params: { productId: product.value.id }
        },
        isEnabled: isAssetManagementFeatureEnabled.value
      }
    ];
  }
  return [];
});
const productPermissionsLinks = computed<HeaderNavigationLink[]>(() => {
  if (product.value) {
    return [
      {
        title: "User Permissions",
        isHighlighted: currentRoute.value === "product-permissions",
        to: {
          name: "product-permissions",
          params: { productId: product.value.id }
        },
        isEnabled: true
      },
      {
        title: "Feature Management",
        dataTesting: "feature-management",
        isHighlighted: currentRoute.value === "feature-management",
        to: {
          name: "feature-management",
          params: { productId: product.value.id }
        },
        isEnabled: true
      }
    ];
  }
  return [];
});
</script>

<style lang="scss" scoped>
header {
  padding-left: $sidebar-width-sm;
  width: 100%;
  background-color: $bg-color;
  transition: padding-left 0.3s ease-out;
  &.is-sidebar-open {
    padding-left: $sidebar-width;
    @media (max-width: $breakpoint-md) {
      padding-left: $sidebar-width-md;
    }
  }
}
</style>

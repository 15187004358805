export const TOOLS = {
  TYPES: [
    {
      name: "No Authoring Tool Selected",
      id: 0,
      key: "noToolSelected"
    },
    {
      name: "QTI question bank",
      id: 1,
      key: "qtiQuestionBank"
    },
    // Note: DITA not enabled yet
    // {
    //   name: "DITA document",
    //   id: 2
    // },
    {
      name: "MCQ Mode",
      id: 3,
      key: "McqMode"
    },
    {
      name: "External content",
      id: 4,
      key: "externalContent"
    },
    {
      name: "HTML Editor",
      id: 5,
      key: "htmlEditor"
    }
  ],
  ID: {
    mcq: 3,
    html: 5
  },
  isContentObject(categoryId: number): boolean {
    return 2 === categoryId;
  },
  isQTITool(toolId: number): boolean {
    return toolId === 1;
  },
  isMCQTool(toolId: number): boolean {
    return toolId === 3;
  },
  isHTMLTool(toolId: number): boolean {
    return toolId === 5;
  },
  isExternalContentTool(toolId: number): boolean {
    return toolId === 4;
  }
};

export const METADATA = {
  TYPES: [
    {
      id: 0,
      name: "Text Free",
      title: "Freetext"
    },
    {
      id: 1,
      name: "Single Option",
      title: "Select single option"
    },
    {
      id: 2,
      name: "Multiple Options",
      title: "Select multiple options"
    }
  ],
  FRIENDLY_URL_PATTERN: /^[a-z0-9-]{1,}$/,
  FRIENDLY_URL_REPLACE_PATTERN: /[^a-z0-9-]/g
};
